export const FirebaseConfig = {
	"projectId": "taximw",
	"appId": "1:161750386934:web:0909523fe21cb5f6e5c4d7",
	"databaseURL": "https://taximw-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "taximw.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDKDHoVEnZRtEj-ioYO6Yh9g97nkyuBcp8",
	"authDomain": "taximw.firebaseapp.com",
	"messagingSenderId": "161750386934",
	"measurementId": "G-YMFB449JBE"
};