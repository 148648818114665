import {
    FETCH_PREPAID_NUMBERS,
    FETCH_PREPAID_NUMBERS_SUCCESS,
    FETCH_PREPAID_NUMBERS_FAILED,
    EDIT_PREPAID_NUMBER
} from "../store/types";

// Initial state
export const INITIAL_STATE = {
    numbers: [],
    loading: false,
    error: {
        flag: false,
        msg: null
    }
};

// Reducer for managing prepaid numbers
export const prepaidNumbersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PREPAID_NUMBERS:
            return { ...state, loading: true };

        case FETCH_PREPAID_NUMBERS_SUCCESS:
            return {
                ...state,
                numbers: action.payload,
                loading: false
            };

        case FETCH_PREPAID_NUMBERS_FAILED:
            return {
                ...state,
                numbers: [],
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };

        case EDIT_PREPAID_NUMBER:
            return {
                ...state,
                numbers: state.numbers.map(number =>
                    number.id === action.payload.number.id ? action.payload.number : number
                )
            };

        default:
            return state;
    }
};
