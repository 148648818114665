import React, { useState, useEffect, useMemo } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import { Switch, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from "styles/tableStyle";
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function PrepaidNumbers() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { fetchPrepaidNumbers, editPrepaidNumber } = api;
  const prepaidData = useSelector(state => state.prepaidData || {});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // Assuming role is coming from the Redux store
  const role = useSelector(state => state.userRole); // Replace with your actual state structure

  // Fetch prepaid numbers on mount
  useEffect(() => {
    dispatch(fetchPrepaidNumbers());
  }, [dispatch, fetchPrepaidNumbers]);

  // Update state with fetched prepaid numbers
  useEffect(() => {
    if (prepaidData?.numbers) {
      setData(prepaidData.numbers);
    } else {
      setData([]);
    }
  }, [prepaidData]);

  // Sort data
  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      const sorted = [...data].sort((a, b) => a.number - b.number);
      setSortedData(sorted);
    } else {
      setSortedData([]);
    }
  }, [data]);

  // Handle toggling "used" status
  const handleToggleUsed = (rowData) => {
    dispatch(editPrepaidNumber({ ...rowData, used: !rowData.used }, "Update"));
  };

  // Define table columns
  const columns = useMemo(() => [
    { title: t('number'), field: 'number' },
    { title: t('value'), field: 'value', type: 'numeric' },
    {
      title: t('used'), field: 'used', type: 'boolean', render: (rowData) => (
        <Switch
          checked={rowData.used}
          onChange={() => handleToggleUsed(rowData)}
          disabled={role !== 'admin'} // Assuming `role` is defined somewhere in your component
        />
      )
    },
    {
      title: t('created_at'),
      field: 'createdAt',
      type: 'datetime',
      render: rowData => new Date(rowData.createdAt).toLocaleString()
    }
  ], [t, role]);

  // Handle adding prepaid numbers
  const handleAddPrepaidNumbers = () => {
    const count = prompt("Enter the number of prepaid numbers to generate:");
    const value = prompt("Enter the value for each prepaid number:");
    if (count && value) {
      const prepaidNumbers = api.generatePrepaidNumbers(Number(count), Number(value));
      prepaidNumbers.forEach(number => {
        dispatch(editPrepaidNumber(number, "Add"));
      });
    }
  };

  // Handle deleting multiple selected prepaid numbers
  const handleDeleteSelected = () => {
    selectedRows.forEach(row => {
      dispatch(editPrepaidNumber(row, "Delete"));
    });
    setSelectedRows([]);
  };

  return (
    prepaidData.loading ? <CircularLoading /> :
      <ThemeProvider theme={theme}>
        {prepaidData?.error?.flag ? (
          <div style={{ padding: '20px', textAlign: 'center', color: 'red' }}>
            {prepaidData.error.msg ? prepaidData.error.msg : t('prepaid_numbers_error')}
          </div>
        ) : sortedData.length > 0 ? (
          <div>
            <Button onClick={handleAddPrepaidNumbers} variant="contained" color="primary">
              {t('add_prepaid_numbers')}
            </Button>
            <Button
              onClick={handleDeleteSelected}
              variant="contained"
              color="secondary"
              disabled={selectedRows.length === 0}
              style={{ marginLeft: '10px' }}
            >
              {t('delete_selected')}
            </Button>
            <MaterialTable
              title={t('prepaid_numbers_title')}
              columns={columns}
              data={sortedData}
              style={{
                direction: isRTL === "rtl" ? "rtl" : "ltr",
                borderRadius: "8px",
                boxShadow: `0px 2px 5px ${colors.SECONDARY_COLOR}`,
                padding: "20px",
              }}
              options={{
                filtering: true,
                selection: true,
                rowStyle: (rowData) => ({
                  backgroundColor: selectedRows.includes(rowData) ? colors.ROW_SELECTED : colors.WHITE
                }),
                ...TableStyle()
              }}
              localization={localization(t)}
              onSelectionChange={(rows) => setSelectedRows(rows)}
              editable={{
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      dispatch(editPrepaidNumber(oldData, "Delete"));
                    }, 600);
                  })
              }}
            />
          </div>
        ) : (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            {t('no_prepaid_numbers_available')}
          </div>
        )}
      </ThemeProvider>
  );
}
