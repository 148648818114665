import {
  FETCH_PREPAID_NUMBERS,
  FETCH_PREPAID_NUMBERS_SUCCESS,
  FETCH_PREPAID_NUMBERS_FAILED,
  EDIT_PREPAID_NUMBER
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, push, set, remove } from "firebase/database";

// Action to fetch prepaid numbers from Firebase
export const fetchPrepaidNumbers = () => (dispatch) => {
  const { prepaidNumbersRef } = firebase;

  dispatch({ type: FETCH_PREPAID_NUMBERS });

  const unsubscribe = onValue(prepaidNumbersRef, snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i];
      });

      dispatch({ type: FETCH_PREPAID_NUMBERS_SUCCESS, payload: arr });
    } else {
      dispatch({ type: FETCH_PREPAID_NUMBERS_FAILED, payload: "No prepaid numbers available." });
    }
  }, (error) => {
    dispatch({ type: FETCH_PREPAID_NUMBERS_FAILED, payload: error.message });
  });

  return () => unsubscribe(); // Cleanup on unmount
};

// Action to add, update, or delete a prepaid number
export const editPrepaidNumber = (number, method) => (dispatch) => {
  const { prepaidNumbersRef } = firebase;

  dispatch({
    type: EDIT_PREPAID_NUMBER,
    payload: { method, number }
  });

  switch (method) {
    case 'Add':
      push(prepaidNumbersRef, number);
      break;
    case 'Delete':
      remove(prepaidNumbersRef.child(number.id));
      break;
    case 'Update':
      set(prepaidNumbersRef.child(number.id), number);
      break;
    default:
      console.error("Invalid method provided for editPrepaidNumber");
  }
};


// Function to generate unique prepaid numbers
export const generatePrepaidNumbers = (count, value) => {
  const numbers = [];
  const usedNumbers = new Set();

  while (numbers.length < count) {
    let number = Math.floor(Math.random() * 90000000) + 10000000; // Generate an 8-digit number
    if (!usedNumbers.has(number.toString())) {
      numbers.push({ 
        number: number.toString(), 
        value, 
        used: false, 
        createdAt: new Date().toISOString()  // Add creation date
      });
      usedNumbers.add(number.toString());
    }
  }

  return numbers;
};